@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/variables';
@import '~@fortawesome/fontawesome-free/scss/mixins';
@import '~@fortawesome/fontawesome-free/scss/core';
@import '~@fortawesome/fontawesome-free/scss/list';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

:host {
  display: inline-block;
  vertical-align: middle;

  button {
    @include button(false, $highlight-color, scale-color($highlight-color, $lightness: -15%), $white);
    background-color: $primary-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    width: 100%;

    &.tiny {
      font-size: map-get($map: $button-sizes, $key: 'tiny');
      padding: 0.15rem 0.25rem;
    }

    &.small {
      font-size: map-get($map: $button-sizes, $key: 'small');
    }

    &.large {
      font-size: map-get($map: $button-sizes, $key: 'large');
    }

    &.disabled {
      @include button-disabled($button-opacity-disabled);
    }
  }

  i.fas {
    @extend %fa-icon;
    @extend .fas;

    margin-right: 0.25em;

    &.fa-sign-in-alt {
      &:before {
        content: fa-content($fa-var-sign-in-alt);
      }
    }
    &.fa-sign-out-alt {
      &:before {
        content: fa-content($fa-var-sign-out-alt);
      }
    }
    &.fa-hourglass-start {
      &:before {
        content: fa-content($fa-var-hourglass-start);
      }
    }
  }
}